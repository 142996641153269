<script setup lang="ts">
import { CornerPosition } from '~/components/Common/ClipContainer.vue';

const props = withDefaults(defineProps<{
    isEnabled?: boolean;
    cornerSize?: number;
    additionalCornersPosition?: CornerPosition;
}>(), {
    isEnabled: true,
    cornerSize: 16,
    additionalCornersPosition: undefined,
});

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
const cornerParams = computed(() => ({ type: 'angle', width: props.cornerSize, height: props.cornerSize }));

const additionalCorner = computed(() => props.additionalCornersPosition ?
    ({ [props.additionalCornersPosition]: cornerParams.value }) : {});
</script>

<template>
    <CommonButton
        class="button"
        :corners="{
            br: { type: 'angle', width: cornerSize, height: cornerSize },
            ...additionalCorner,
        }"
        :is-enabled="isEnabled"

        @click="emit('click', $event)"
    >
        <slot name="icon" />

        <h1 class="button__text">
            <slot />
        </h1>
    </CommonButton>
</template>

<style scoped lang="scss">
.button {
    --enabled-background-color: #{$color-primary};
    --hover-background-color: #{lighten($color-primary, 5%)};
    --active-background-color: #{darken($color-primary, 5%)};

    &__text {
        font-family: #{var(--font-family, $font-family-base)};
        font-size: size(var(--font-size-raw, 22px));
        font-style: normal;
        font-weight: var(--font-weight, 400);
        line-height: normal;

        color: $color-black;
        transition: color $base-transition;
    }
}
</style>
